/* eslint-disable arrow-parens,arrow-body-style,react/prop-types */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LogInForm from './components/LogInForm';
import RegistrationForm from './components/RegistrationForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import { loginUser, signUpUser, clearUserAlert, resendVerificationEmail} from '../../redux/actions/users';
import { Alert, Button } from 'reactstrap';
import {setWhiteLabel} from '../../redux/actions/whiteLabel';
// import ReactGA from 'react-ga';
import welomeLogo from '../../shared/img/logo/welcome-banner-logo.svg'
import { TrackGoogleAnalyticsEvent } from '../../shared/utils/google-analytics';
import GoogleButton from '../../shared/GoogleButton';
import Cookies from 'js-cookie';


class LogIn extends React.Component {

  state = {
    formType: this.props.match.path.substring(1),
    invitationId: '',
  };

  componentDidMount() {
    const queryParameters = new URLSearchParams(window.location.search)
    const invitationId = queryParameters.get('invitationId')
    const linkPreview = queryParameters.get('link-preview-tool')
    if (invitationId) {
      this.setState({ invitationId: invitationId });
    }
    if(linkPreview){
      Cookies.set('dashBoardView', 'marketing', { expires: 365 });
    }
  };


  redirectIfLoggedIn = () => {
    // if cookie dashboardView is marketing, redirect to link-preview
    const marketingCookie = Cookies.get('dashBoardView');
    if(this.props.user){
      if (marketingCookie === 'marketing') {
        this.props.history.push('/link-preview');
      } else if(this.props.whiteLabel.whiteLabelMode){
        this.props.history.push(`/apis/${this.props.whiteLabel.whiteLabelApi.uriFriendlyName}`)
      } else {
        this.props.history.push('/dashboard')
      }
    }
  };

  signInToGoogle = () => {
    TrackGoogleAnalyticsEvent('googleSignInOrSignUp', 'Sign Up Flow', 'googleSignInOrSignUp', 'login_register')
    // ReactGA.event({
    //   category: 'onboard',
    //   action: 'login',
    //   label: 'google'
    // });
  };

  signUpUser = () => {
      this.props.signUpUser()
  };

  switchFormClicked = () => {
    if (this.state.formType === 'register'){
      this.props.history.push('/login');
      this.setState({formType: 'login'})
    } else if(this.state.formType === 'login'){
      this.props.history.push('/register');
      this.setState({formType: 'register'})
    } else {
      this.props.history.push('/login');
      this.setState({formType: 'login'})
    }
  };

  renderForm = () => {
    if(this.state.formType === 'register'){
      return <RegistrationForm invitationId={this.state.invitationId} flipForm={this.switchFormClicked}/>
    } else if (this.state.formType === 'resetpassword'){
      return <ResetPasswordForm loginUser={this.props.loginUser} signUpUser={this.signUpUser} formData={this.props.formData} />
    } else {
      return <LogInForm loginUser={this.props.loginUser} signUpUser={this.signUpUser} formData={this.props.formData} history={this.props.history} switchToPasswordResetForm={this.switchToPasswordResetForm}/>
    }
  };

  renderHeadOfForm = () => {
    if(this.props.whiteLabel.whiteLabelMode){
      return (
        <div>
          <h3 className="account__profile">
            <span className="account__logo">OpenGraph
                  <span className="account__logo-accent">.</span>
                  <span>io</span>
                </span>
          </h3>
        </div>
      )
    } else {
      return (
        <div className="account__head">
          <h3 className="account__title">Welcome to
            <span className="account__logo"> API
                  <span className="account__logo-accent">FUR</span>
                </span>
          </h3>
          <h4 className="account__subhead subhead">Come in and REST your feet.</h4>
        </div>
      )
    }
  };

  switchToPasswordResetForm = () => {
    this.setState({formType: 'resetpassword'})
  };

  redirectRootToLogin = () => {
    if(this.state.formType === ''){
      this.setState({formType: 'login'})
    }
  };



  render() {
    const alert = this.props.alert || {};
    if(this.props.whiteLabel.loading) {
      this.props.setWhiteLabel(window.location.hostname);
    } else {
      this.redirectIfLoggedIn();
    }
    this.redirectRootToLogin();
    let apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'http://localhost:3777';

    const handleSignInButtonClick = () => {
      TrackGoogleAnalyticsEvent('googleSignInOrSignUp', 'Sign Up Flow', 'googleSignInOrSignUp', 'login_register')
      window.location.href = apiUrl + '/auth/google';
    };


    return (
      <div className="account">
        <div className="account__wrapper row">
          <div className="account__card col">
            <div className="account__card-container">
            <Alert color={alert.color} className="alert--bordered" isOpen={!!alert.message} toggle={this.props.clearUserError}>
              <div className={'padding5'}>
                <span>{alert.message}</span>
                {alert.message === 'Please Verify Email.' && <span className={'alert-link clickable'} onClick={this.props.resendVerificationEmail}> Resend Email</span>}
              </div>
            </Alert>
            {this.renderHeadOfForm()}
            {this.renderForm()}
            <Button color={'link'} siz={'sm'} className="btn btn-outline-primary account__btn account__btn--small" onClick={this.switchFormClicked}>{this.state.formType === 'login' ? 'Register' : 'Back To Sign In'  }</Button>
            <div className="account__or">
              <p>OR</p>
            </div>
            <div style={{ width: '100%'}}>
              <button style={{ border: 'none', width: '100%', background: 'none', padding: 0}} onClick={handleSignInButtonClick}>
                <GoogleButton />
              </button>
            </div>
            </div>
          </div>
          <div className={'account__welcome col'}>
            <div className={'account__welcome-container'}>
              <div className={'account__welcome-title'}>
              <h3 className="account__welcome-logo">
                  <span className="account__logo-accent">Welcome </span>
                  <span>to Open Graph API</span>
              </h3>
              <p>A very simple Open Graph API</p>
                </div>
              <img src={welomeLogo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
    formData: state.form.log_in_form,
    alert: state.users.alert,
    whiteLabel: state.whiteLabel
  };
};

const mapDispatchToProps = (dispatch) => {
  return ({
    loginUser: (payload) => dispatch(loginUser(payload)),
    signUpUser: (payload) => dispatch(signUpUser(payload)),
    clearUserError: () => dispatch(clearUserAlert()),
    resendVerificationEmail: () => dispatch(resendVerificationEmail()),
    setWhiteLabel: (payload) => dispatch(setWhiteLabel(payload))
  });
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogIn));

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
