import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormGroup, Label } from 'reactstrap';
import { BsStars } from 'react-icons/bs';
import {
  generateAiMetaData,
  handleEditMetaData, handleEditMetaDataFromResults,

} from '../../../redux/actions/linkPreview';
import { connect } from 'react-redux';

const AiModal = ({ visible, toggle, seoSuggestions, handleInformationEdit, generateAiResponse }) => {
  const [metaInformation, setMetaInformation] = useState({
    title: '',
    description: '',
    image: ''
  });
  const [context, setContext] = useState('');

  useEffect(() => {
    if (seoSuggestions.information) {
      setMetaInformation({
        title: seoSuggestions.information.title || '',
        description: seoSuggestions.information.description || '',
        image: ''
      });
    }
  }, [seoSuggestions]);

  const handleGenerate = async () => {
    generateAiResponse();
  };

  const handleAcceptBoth = () => {
    handleInformationEdit('title', metaInformation.title);
    handleInformationEdit('description', metaInformation.description);
    toggle(); // Close the modal
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setMetaInformation((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Modal isOpen={visible} toggle={toggle}>
      <ModalHeader toggle={toggle}>Generate SEO Title & Description</ModalHeader>
      <ModalBody>
        <p>Provide additional context (optional) to help improve the SEO content.</p>
        <FormGroup>
          <Label for="contextInput">Additional Context (up to 250 characters):</Label>
          <Input
            type="textarea"
            id="contextInput"
            maxLength="250"
            value={context}
            name={'context'}
            onChange={(e) => setContext(e.target.value)}
            placeholder="Enter relevant context here..."
          />
        </FormGroup>

        {/* Title Input */}
        <FormGroup>
          <Label for="seoTitle">SEO Title</Label>
          {seoSuggestions.loading ? (
            <div className="input-loading-placeholder" />
          ) : (
            <Input
              type="text"
              id="seoTitle"
              name="title"
              value={metaInformation.title}
              onChange={handleEdit}
              maxLength="60"
              disabled={seoSuggestions.loading}
            />
          )}
        </FormGroup>

        {/* Description Input */}
        <FormGroup>
          <Label for="seoDescription">SEO Description</Label>
          {seoSuggestions.loading ? (
            <div className="input-loading-placeholder" />
          ) : (
            <Input
              type="textarea"
              id="seoDescription"
              name="description"
              value={metaInformation.description}
              onChange={handleEdit}
              maxLength="160"
              disabled={seoSuggestions.loading}
            />
          )}
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <div className="btn-purple" onClick={handleGenerate}>
          <BsStars /> Optimize with AI
        </div>
        <Button color="success" onClick={handleAcceptBoth} disabled={seoSuggestions.loading}>
          Accept
        </Button>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  seoSuggestions: state.linkPreview.seoAiMetaTags
});

const mapDispatchToProps = (dispatch) => ({
  handleInformationEdit: (name, value) => dispatch(handleEditMetaDataFromResults(name, value)),
  generateAiResponse: () => dispatch(generateAiMetaData())
});

export default connect(mapStateToProps, mapDispatchToProps)(AiModal);
