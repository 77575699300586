import {
  EDIT_LINK_PREVIEW_META_DATA,
  EDIT_UPDATED_RESULTS,
  MAKE_LINK_PREVIEW_CALL,
  MAKE_LINK_PREVIEW_CALL_ERROR,
  MAKE_LINK_PREVIEW_CALL_SUCCESS,
  SET_ACTIVE_LINK_PREVIEW_TAB,
  TOGGLE_MARKETING_SIGN_UP_MODAL,
  SET_SEO_AI_META_TAGS,
  TOGGLE_AI_MODAL,
  GENERATE_SEO_TAGS,
  GENERATE_SEO_TAGS_SUCCESS,
  GENERATE_SEO_TAGS_ERROR,
  TOGGLE_MISSING_TAGS_ALERT,
  GET_IMAGE_LAYOUTS,
  GET_IMAGE_LAYOUTS_SUCCESS, GET_IMAGE_LAYOUTS_FAIL
} from '../actionTypes';

const initialState = {
  form: {
    url: '',
    title: '',
    description: '',
    image: ''
  },
  results: null,
  newResults: null,
  updatedResults: null,
  isSubmitting: false,
  error: null,
  success: null,
  loading: false,
  showCaptcha: false,
  showSignUpModal: false,
  showMissingTagsAlert: false,
  metaTags: {
    title: '',
    description: '',
    image: '',
    url: '',
    type: ''
  },
  seoAiMetaTags: {
    aiModalOpen: false,
    loading: false,
    error: {
      message: '',
      error: ''
    },
    information: {
      title: '',
      description: '',
      extraContext: ''
    }
  },
  activeTab: 'facebook',
  imageLayouts: {
    loading: false,
    error: null,
    layouts: [
      {
        imageUrl: '',
        name: '',
        id: 0,
        params: ['title']
      }
    ],
  }
}

const linkPreview = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_LINK_PREVIEW_META_DATA:
      return {
        ...state,
        form: {
          ...state.form,
          [action.name]: action.value
        },
        metaTags: {
          ...state.metaTags,
          [action.name]: action.value
        }
      };
    case EDIT_UPDATED_RESULTS:
      return {
        ...state,
        results: {
          ...action.payload
        }
      };
    case SET_ACTIVE_LINK_PREVIEW_TAB:
      return {
        ...state,
        activeTab: action.tab
      };
    case MAKE_LINK_PREVIEW_CALL:
      return {
        ...state,
        loading: true
      };
    case MAKE_LINK_PREVIEW_CALL_SUCCESS:
      return {
        ...state,
        loading: false,
        results: action.results
      };
    case MAKE_LINK_PREVIEW_CALL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.err
      };
    case TOGGLE_MARKETING_SIGN_UP_MODAL:
      return {
        ...state,
        showSignUpModal: action.open
      };
    case TOGGLE_AI_MODAL:
      return {
        ...state,
        seoAiMetaTags: {
          ...state.seoAiMetaTags,
          aiModalOpen: action.open
        }
      };
    case GENERATE_SEO_TAGS:
      return {
        ...state,
        loading: true,
        seoAiMetaTags: {
          ...state.seoAiMetaTags,
          loading: true
        }
      };
    case GENERATE_SEO_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        results: {
          ...state.results,
          hybridGraph: {
            ...state.results.hybridGraph,
            ...action.payload
          },
          openGraph: {
            ...state.results.openGraph,
            ...action.payload
          },
          htmlInferred: {
            ...state.results.htmlInferred,
            ...action.payload
          }
        },
        seoAiMetaTags: {
          ...state.seoAiMetaTags,
          loading: false,
          information: action.payload
        }
      };
    case GENERATE_SEO_TAGS_ERROR:
      return {
        ...state,
        loading: false,
        seoAiMetaTags: {
          ...state.seoAiMetaTags,
          loading: false,
          error: action.payload
        }
      };
    case SET_SEO_AI_META_TAGS:
      return {
        ...state,
        seoAiMetaTags: {
          ...state.seoAiMetaTags,
          ...action.payload
        }
      };
    case TOGGLE_MISSING_TAGS_ALERT:
      return {
        ...state,
        showMissingTagsAlert: action.open
      };
    case GET_IMAGE_LAYOUTS:
      return {
        ...state,
        imageTemplates: {
          ...state.imageTemplates,
          loading: true
        }
      };
    case GET_IMAGE_LAYOUTS_SUCCESS:
      return {
        ...state,
        imageLayouts: {
          ...state.imageTemplates,
          loading: false,
          layouts: action.results
        }
      };
    case GET_IMAGE_LAYOUTS_FAIL:
      return {
        ...state,
        imageTemplates: {
          ...state.imageTemplates,
          loading: false,
          error: action.payload
        }
      };
    default:
      return state;
  }
}

export default linkPreview;
