import React, {useEffect, useState} from 'react';
// import {CardElement} from '@stripe/react-stripe-js';
import {Button} from 'reactstrap';
import {connect} from 'react-redux';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import {apiBase} from '../../../constants/config';
import {setStripeError} from '../../../redux/actions/plans';
import { TrackGoogleAnalyticsEvent } from '../../../shared/utils/google-analytics';
import Cookies from 'js-cookie';



const StripePaymentForm = (props) => {
    const [sourceLoading, setSourceLoading] = useState(false);
    const {loading, buttonText, user, tier, useExistingSource, useExistingSubmit, setStripeError, justChangeCard} = props;

    const handleError = (error) => {
        console.log({error});
        setSourceLoading(false);
        setStripeError(error.message);
    }

    const handleTierSwitch = async (stripe, elements) => {
        // create subscription
        const res = await fetch(`${apiBase}/api/v1/organizations/${user.defaultOrgId}/subscriptions/${tier.id}`, {
            method: 'POST',
            credentials: 'include',
        });
        const result = await res.json();

        const {type, clientSecret, status, paymentIntentId} = result;
        const confirmIntent = type === 'setup'? stripe.confirmSetup : stripe.confirmPayment;

        if(clientSecret){
            // confirm intent
            const {error} = await confirmIntent({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: `${apiBase}/api/v1/organizations/${user.defaultOrgId}/subscriptions/${tier.id}/callback`,
                },
            });

            if (error) {
                // This point is only reached if there's an immediate error when confirming the Intent.
                // Show the error to your customer (for example, "payment details incomplete").
                handleError(error);

                const res = await fetch(`${apiBase}/api/v1/organizations/${user.defaultOrgId}/subscriptions/${tier.id}`, {
                    method: 'DELETE',
                    credentials: 'include',
                });
                const result = await res.json();
            }

            // if there is no error, customer will get redirected to the backend which will complete the subscription then back to dashboard page.
            TrackGoogleAnalyticsEvent('tierChange','Usage Metrics','tierChange', tier.name, tier.priceMonthly);
        } else {
            console.error('no client secret');
        }
    }

    const handleUpdateCard = async (stripe, elements) => {


        const res = await fetch(`${apiBase}/api/v1/organizations/${user.defaultOrgId}/payment`, {
            method: 'POST',
            credentials: 'include',
        });
        const result = await res.json();

        const {type, clientSecret, status, paymentIntentId} = result;
        const confirmIntent = type === 'setup'? stripe.confirmSetup : stripe.confirmPayment;

        if(clientSecret){
            // confirm intent
            const {error} = await confirmIntent({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: `${apiBase}/api/v1/organizations/${user.defaultOrgId}/payment/callback`,
                },
            });

            if (error) {
                // This point is only reached if there's an immediate error when confirming the Intent.
                // Show the error to your customer (for example, "payment details incomplete").
                handleError(error);
            }

            // if there is no error, customer will get redirected to the backend which will complete the subscription then back to dashboard page.
        } else {
            console.error('no client secret');
        }
    }

    const handlePaymentElementsSubmit = async (e, stripe, elements, tier) => {
        const payment = elements.getElement(PaymentElement);
        setSourceLoading(true);
        const {error: submitError} = await elements.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }

        if(justChangeCard){
            return handleUpdateCard(stripe, elements);
        } else {
            return handleTierSwitch(stripe, elements);
        }

    }

    const handleSubmit = async  (e, stripe, elements, tier) => {
        e.preventDefault();
        if(useExistingSource){
            return useExistingSubmit();
        } else {
            return handlePaymentElementsSubmit(e, stripe, elements, tier);
        }
    };

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        // this will screw up payment process because user will get sent back to the wrong spot
        Cookies.remove('dashboardView');
    }, [loading]);

    return (
        <form onSubmit={(e) => handleSubmit(e, stripe, elements, tier)}>
            {loading ? (
                <div style={{ display: 'flex', height: '200px', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="spinner-border text-success centerText" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : <PaymentElement/>}
            <br/>
            <Button
                disabled={loading || sourceLoading}
                className="btn btn-primary account__btn account__btn--small whiteText greenButton"
            >
                {buttonText}
            </Button>
            <div className={'rightText'}>
                <a href="https://stripe.com/us/features#seamless-security" className={'rightText'}>
                    <img src="/Stripe-Seal.png" className={'width20'}/>
                </a>
            </div>
        </form>
    )
}

const mapStateToProps = (state) => ({
    loading: state.plans.loading,
    stripeError: state.plans.stripeError,
    user: state.users.user
})

const mapDispatchToProps = (dispatch) => ({
    setStripeError: (payload) => dispatch(setStripeError(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(StripePaymentForm)

