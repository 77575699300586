import React, { useEffect } from 'react';
import { Card, CardBody, Col, Table } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { getUserInvoices } from '../../../redux/actions/users';

const InvoicesTable = ({ invoices = [], getUserInvoices, currentOrganization }) => {

  useEffect(() => {
    if(invoices.length === 0 && currentOrganization.id) {
      getUserInvoices();
    }
  }, [currentOrganization]);

  const renderRows = (invoices) => {
    const sortedInvoices = invoices.sort((a, b) => a.date - b.date).slice(0, 10);
    return sortedInvoices.map((invoice, index) => {
      return (
          <tr key={`invoiceRow-${invoice.id}-${index}`}>
            <td>{moment.unix(invoice.period_end).format('MMM Do YYYY')}</td>
            <td>{`$${(invoice.amount_due / 100).toFixed(2)}`}</td>
            <td>{invoice.status}</td>
            <td>
              <a href={invoice.hosted_invoice_url} target="_blank" rel="noopener noreferrer">
                Invoice
              </a>
            </td>
            <td>
              <a href={invoice.invoice_pdf} download>
                Download
              </a>
            </td>
          </tr>
      )

    });
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="subhead">Invoices</h5>
          </div>
          <Table className="table--bordered" responsive striped>
            <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th>View Online</th>
              <th>PDF</th>
            </tr>
            </thead>
            <tbody>{renderRows(invoices)}</tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  invoices: state.users.invoices,
  currentOrganization: state.organization.currentOrg.information,
});

const mapDispatchToProps = (dispatch) => ({
  getUserInvoices: () => dispatch(getUserInvoices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesTable);
