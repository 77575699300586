import React, {useEffect, useState} from 'react';
import {Alert, Modal, ModalBody} from 'reactstrap';
// for some reason import from '@stripe/stripe-js'; does not work
// import * as Stripe from '../../../../node_modules/\@stripe/stripe-js/dist/index.js';
import * as Stripe from '\@stripe/stripe-js/dist/stripe.js';
import {Elements} from '@stripe/react-stripe-js';
import StripePaymentForm from './StripePaymentForm';
import {connect} from 'react-redux';
import {toggleStripeModal} from '../../../redux/actions/stripeModal';
import {clearStripeError, createPlan, updatePlan, setStripeError} from '../../../redux/actions/plans';
import { TrackGoogleAnalyticsEvent } from '../../../shared/utils/google-analytics';
import { getOrganizationStripeInfo } from '../../../redux/actions/organization';

const stripeKey = process.env.REACT_APP_STRIPE_ENV === 'test'
    ? 'pk_test_7nyKxLOrQClPS161Ix93dyzt'
    : 'pk_live_30x8uLqquZQVmiazaPvQfKJc'
const stripePromise = Stripe.loadStripe(stripeKey);

const StripeSignupOrSwitchModal = (props) => {
    const {
        tier,
        plan,
        stripeInfo,
        toggleStripeModal,
        updatePlan,
        stripeError,
        clearStripeError,
        visible,
        currentOrg,
        getOrganizationStripeInfo
    } = props;
    const [useExistingSource, setUseExistingSource] = useState(!!props.source);
    const [paymentMethod, setPaymentMethod] = useState();

    useEffect(() => {
        if (currentOrg && currentOrg.id) {
            if(!stripeInfo.data.id && !stripeInfo.loading){
                getOrganizationStripeInfo(currentOrg.id)
            }
        }
    }, [currentOrg]);

    useEffect(() => {
        if(stripeInfo.data && stripeInfo.data.invoice_settings && stripeInfo.data.invoice_settings.default_payment_method) {
            setPaymentMethod(stripeInfo.data.invoice_settings.default_payment_method)
        }
    }, [stripeInfo]);


    const toggleCheckBox = () => {
        setUseExistingSource(!useExistingSource)
    };

    const useExistingSubmit = () => {
        let payload = {
            tierId: tier.id,
            id: plan.id,
            tier
        };

        if(props.source){
            payload.stripeSource = props.source
        }

        TrackGoogleAnalyticsEvent('tierChange','Usage Metrics','tierChange', tier.name, tier.priceMonthly);
        updatePlan(payload)
    }

    const renderLastFourForCard = () => {
        if(paymentMethod){
            return `**** **** **** ${paymentMethod.card.last4}`
        } else if (props.source && props.source.source && props.source.source.last4) {
            return `**** **** **** ${props.source.source.last4}`
        } else {
            return '**** **** **** ****'
        }
    }

    const renderHeaderMessage = () => {
        if (tier.trialTierId && !plan.hasUsedFreeTrial) {
            return (
                <div className={'freeTrialMessage'}>
                    <h4 className={'bold-text'}>7 Day Free Trial</h4>
                    <p className={'greyText'}>Test OpenGraph.io fully risk free for 7 days.</p>
                    <p style={{ textAlign: 'left' }} className={'greyText'}>Your card will NOT be charged until either of the following:</p>
                    <ul className={'greyText'}>
                        <li style={{  listStyle: 'unset' }}><p style={{ fontSize: '12px', textAlign: 'left' }} className={'greyText'}>You reach the end of the trial</p></li>
                        <li style={{  listStyle: 'unset' }}><p style={{ fontSize: '12px', textAlign: 'left' }} className={'greyText'}>You reach or go over a 25,000 Request limit</p></li>
                    </ul>
                    <p style={{ textAlign: 'left' }} className={'greyText'}>You will have 25,000 total requests during the trial period.</p>
                    <p style={{ textAlign: 'left' }} className={'greyText'}>At the end of your trial you will be charged $25 and your Requests will reset with a new limit of 50,000.</p>
                </div>
            )
        }
        return <h4 className={'bold-text modal__title'}>
            Update Plan
        </h4>
    }

    const stripeOptions = {
        mode: 'subscription',
        amount: tier.priceMonthly * 100,
        currency: 'usd',
    }

    const hasPaymentMethod = !!props.source || !!paymentMethod;

    if(!tier || !tier.id){
        return null
    }
    return (
        <Elements stripe={stripePromise} options={stripeOptions}>
            <Modal isOpen={visible} toggle={() => toggleStripeModal(!visible)}
                   className={'supportModal modal-dialog--primary paymentModal'} >
                <div className={'modal__header centerText bottomBorder'}>
                    <button className="lnr lnr-cross modal__close-btn" onClick={() => toggleStripeModal(!visible)}/>
                    {stripeError ? (
                        <Alert color={'danger'} className="alert--bordered" isOpen={!!stripeError} toggle={() => clearStripeError()}>
                            <div className={'padding5'}>
                                <p>{stripeError}</p>
                            </div>
                        </Alert>) : ( renderHeaderMessage())
                    }
                    <br/>
                </div>
                <ModalBody>
                    {hasPaymentMethod &&
                        <div className="form__form-group">
                            <div className="form__form-group-field flexSpaceBetween">
                                <label>
                                    <input type="checkbox" onChange={toggleCheckBox}
                                           checked={useExistingSource}/>
                                    <span className={'checkbox-btn__label'}> Use Existing Card </span>
                                </label>
                                <span
                                    className={`checkbox-btn__label ${useExistingSource ? '' : 'greyText'}`}>{`${renderLastFourForCard()}`}</span>
                            </div>
                        </div>
                    }
                    <div className={'modal__body leftText borderRadius5'}>
                        {plan.loading ? (
                            <div style={{ display: 'flex', height: '200px', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <div className="spinner-border text-success centerText" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                          ) :
                          <StripePaymentForm
                          useExistingSource={useExistingSource}
                          useExistingSubmit={useExistingSubmit}
                          buttonText={`$${tier.priceMonthly} / Month`}
                          isOpen={!visible}
                          tier={props.tier}
                        />}
                    </div>
                </ModalBody>
            </Modal>
        </Elements>
    )
}

const mapStateToProps = (state) => ({
    stripeError: state.plans.stripeError,
    plan: state.plans.detailPlan,
    source: state.plans.detailPlan.stripeSource,
    tier: state.stripeModal.tier,
    visible: state.stripeModal.visible,
    stripeInfo: state.organization.currentOrg.stripeInfo,
    currentOrg: state.organization.currentOrg.information
})

const mapDispatchToProps = (dispatch) => ({
    toggleStripeModal: (payload) => dispatch(toggleStripeModal(payload)),
    createPlan: (payload) => dispatch(createPlan(payload)),
    updatePlan: (payload) => dispatch(updatePlan(payload)),
    clearStripeError: (payload) => dispatch(clearStripeError(payload)),
    setStripeError: (payload) => dispatch(setStripeError(payload)),
    getOrganizationStripeInfo: (id) => dispatch(getOrganizationStripeInfo(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(StripeSignupOrSwitchModal)
