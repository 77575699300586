import React, {Component} from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import FreeTierCancelForm from './FreeTierCancelForm';
import {connect} from 'react-redux';
import {toggleStripeModal} from '../../../redux/actions/stripeModal';
// import ReactGA from 'react-ga';
import {createPlan, updatePlan} from '../../../redux/actions/plans';
import { TrackGoogleAnalyticsEvent } from '../../../shared/utils/google-analytics';



class FreeTierModal extends Component {

  renderModalBody(){
    if(this.props.currentTierId){
      return (
        <FreeTierCancelForm />
      )
    } else {
      return (
        <div className={'padding10'}>
        </div>
      )
    }
  }

  signUpForTier = (plan, tier, source) => {
    if (plan.id) {
      let payload = {
        tierId: tier.id,
        stripeSource: source,
        id: plan.id,
        tier
      };
      TrackGoogleAnalyticsEvent('tierChange', 'Usage Metrics', 'tierChange', tier.name, tier.priceMonthly)
      this.props.updatePlan(payload)
    } else {
      let payload = {
        tierId: tier.id,
        apiId: tier.apiId,
        startDate: new Date(),
        stripeSource: source
      };
      // We need a way to track the price of the free trial
      TrackGoogleAnalyticsEvent('tierChange', 'Usage Metrics', 'tierChange', tier.name, tier.priceMonthly)
      this.props.createPlan(payload)
    }
  }

  render(){
    const {visible, tier, plan, source, stripeError} = this.props
    const currentTierId = plan.tierId
    return (
      <Modal isOpen={visible} toggle={() => this.props.toggleStripeModal(!visible)} className={'supportModal modal-dialog--primary paymentModal '}>
        <div className={'modal__header centerText bottomBorder'}>
          <button className="lnr lnr-cross modal__close-btn" onClick={() => this.props.toggleStripeModal(!visible)} />
          <h4 className={'bold-text modal__title'}>
            {this.props.tier.name}
          </h4>
          <br />
        </div>
        <ModalBody>
          <div className={'modal__body leftText borderRadius5'}>
            {this.renderModalBody()}
            <Button className="btn btn-primary account__btn account__btn--small whiteText" onClick={() => this.signUpForTier(plan,  tier, source)}>{currentTierId ? 'Downgrade' : 'Sign up for free tier'}</Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  stripeError: state.plans.stripeError,
  plan: state.plans.detailPlan,
  source: state.plans.stripeSource,
  tier: state.stripeModal.tier,
  visible: state.stripeModal.freeModalVisible
})

const mapDispatchToProps = (dispatch) => ({
  toggleStripeModal: (payload) => dispatch(toggleStripeModal(payload)),
  createPlan: (payload) => dispatch(createPlan(payload)),
  updatePlan: (payload) => dispatch(updatePlan(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FreeTierModal)
