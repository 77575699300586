import React, { useEffect, useState } from 'react';
import { Alert, Button, Modal } from 'reactstrap';
import * as Stripe from '\@stripe/stripe-js/dist/stripe.js';
import { Elements } from '@stripe/react-stripe-js';
import StripePaymentForm from '../../../containers/Apis/components/StripePaymentForm';
import { connect } from 'react-redux';
import { TrackGoogleAnalyticsEvent } from '../../utils/google-analytics';
import { createPlan, updatePlan } from '../../../redux/actions/plans';
import { Link } from 'react-router-dom';


const stripeKey = process.env.REACT_APP_STRIPE_ENV === 'test'
  ? 'pk_test_7nyKxLOrQClPS161Ix93dyzt'
  : 'pk_live_30x8uLqquZQVmiazaPvQfKJc'
const stripePromise = Stripe.loadStripe(stripeKey);

const MarketingPaidModal = (props) => {

  const { visible, toggleModal } = props;

  const [alertOpen, setAlertOpen] = useState(false);
  const [ formStep, setFormStep ] = useState(0);

  const [useExistingSource, setUseExistingSource] = useState(!!props.plan.source);
  const [paymentMethod, setPaymentMethod] = useState();

  //Find the tier where the price is 25$
  // const tier = props.tier.find(tier => tier.isTrial) || '';
  // const stripeOptions = {
  //   mode: 'subscription',
  //   amount: tier.priceMonthly * 100,
  //   currency: 'usd',
  // }

  const signUpForTier = (tier, source) => {

    if (props.plan.id) {
      let payload = {
        tierId: tier.id,
        stripeSource: source,
        id: props.plan.id,
        tier
      };

      props.updatePlan(payload)
    } else {

      let payload = {
        tierId: tier.id,
        apiId: tier.apiId,
        startDate: new Date(),
        stripeSource: source,
        tier
      };

      props.createPlan(payload)
    }
  };

  // const useExistingSubmit = () => {
  //   let payload = {
  //     tierId: tier.id,
  //     id: props.plan.id
  //   };
  //
  //   if(props.source){
  //     payload.stripeSource = props.source
  //   }
  //
  //   TrackGoogleAnalyticsEvent('tierChange','Usage Metrics','tierChange', tier.name, tier.priceMonthly);
  //   updatePlan(payload)
  // }




  let apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'https://dashboard-api-test.opengraph.io';

  const toggleFormStep = () => {
    setFormStep(formStep + 1)
  }

  {/*const eventuallyFormStepTwo = (<Elements stripe={stripePromise} options={stripeOptions}>*/}
  {/*    <StripePaymentForm*/}
  {/*      useExistingSource={useExistingSource}*/}
  {/*      useExistingSubmit={useExistingSubmit}*/}
  {/*      buttonText={`$${tier.priceMonthly} / Month`}*/}
  {/*      tier={props.tier}*/}
  {/*    />*/}
  {/*  </Elements>)*/}


  const formSteps = [
    (<><img src='/modal-image-one.svg' alt='modal-image-one' />
      <div style={{ margin: '20px auto'}}>
        <h2 className={'debug--h2'}>Free Tier Limit Reached</h2>
        <p className={'debug--p'}>Sign Up for a paid plan for no rate limits and quicker requests</p>
      </div>
      <div style={{ display: 'flex' }}>
        <Link style={{ marginRight: '2px' }} color={'link'} className="btn btn-primary account__btn account__btn--small whiteText" to={'/apis/billing/Opengraphio'}>Sign Up</Link>
        <Button color={'link'} size={'sm'} onClick={toggleModal} className="btn btn-outline-primary account__btn account__btn--small">Not Now</Button>
      </div>
    </>),
    ]


  const handleAlert = () => {
    setAlertOpen(!alertOpen)
  }

  return (
    <Modal isOpen={visible} className={'login__modal supportModal modal-dialog--primary paymentModal '}>
      {formSteps[formStep]}
    </Modal>

  )
};

const mapStateToProps = (state) => {
  return {
    userState: state.user,
    tier: state.apis.detailApi.tiers,
    plan: state.plans.detailPlan,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createPlan: (payload) => dispatch(createPlan(payload)),
    updatePlan: (payload) => dispatch(updatePlan(payload)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(MarketingPaidModal);
