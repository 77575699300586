import React, {Component} from 'react'
import {Card, CardBody, Col, Button} from 'reactstrap'
import {connect} from 'react-redux'
import {clearStripeError, createPlan, updatePlan} from '../../../redux/actions/plans'
import StripeSignupOrSwitchModal from './StripeSignupOrSwitchModal'
// import ReactGA from 'react-ga'
import check from '../../../shared/img/check.svg'
import mark from '../../../shared/img/mark.svg'
import { TrackGoogleAnalyticsEvent } from '../../../shared/utils/google-analytics';

class TierCard extends Component {

    state = {
        paymentModalVisible: false,
    };

    mapDescriptionToBullets = (tier) => {
        let description = tier.description || '';
        return description.split('|').map((bullet) => {
            if(bullet.startsWith('x')){
                const bulletPoint = bullet.replace('x', '')
                return (
                    <tr>
                        <td className='pricing-card__x'><img alt={'X mark'} src={mark}/></td>
                        <td className="pricing-card__non_feature" key={bulletPoint}>{bulletPoint}</td>
                    </tr>
                )
            }
            return (
                <tr>
                    <td className='pricing-card__check'><img alt={'Checkmark'} src={check}/></td>
                    <td className="pricing-card__feature" key={bullet}>{bullet}</td>
                </tr>
            )
        })
    }

    signUpForTier = (tier, source) => {


        if (this.props.plan.id) {
            let payload = {
                tierId: tier.id,
                stripeSource: source,
                id: this.props.plan.id,
                tier
            };

            this.props.updatePlan(payload)
        } else {

            let payload = {
                tierId: tier.id,
                apiId: tier.apiId,
                startDate: new Date(),
                stripeSource: source
            };

            this.props.createPlan(payload)
        }
    };


    togglePaymentModal = () => {
        this.setState({paymentModalVisible: !this.state.paymentModalVisible})
        this.props.clearStripeError()
    };

    render() {
        const tier = this.props.tier || {};

        return (
            <Col md={6} xl={3} sm={12}>
                <StripeSignupOrSwitchModal source={this.props.plan.stripeSource} signUpForTier={this.signUpForTier}
                                           tier={tier} visible={this.state.paymentModalVisible}
                                           toggle={this.togglePaymentModal}/>
                <Card>
                    <CardBody className="pricing-card__body">
                        <div className='pricing-card__header'>
                        <p className={'pricing-card--emoji'} dangerouslySetInnerHTML={{__html: `${this.props.tier.emoji}`}} />
                        <h3 className="current-billing--info pricing-card__plan">{tier.name}</h3>
                        <p className="pricing-card__price">${tier.priceMonthly}<span> monthly</span></p>
                        </div>
                        <table className='pricing-card-details__table'>
                            {this.mapDescriptionToBullets(tier)}
                        </table>
                        <div className='pricing-card__button_container'>
                        <Button id={tier.name} className="pricing-card__button" color="primary"
                                onClick={() => this.togglePaymentModal()}>{this.props.currentTierId ? 'Switch Plans' : 'Sign Up'}</Button>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

const mapStateToProps = (state) => ({
    plan: state.plans.detailPlan,

});

const mapDispatchToProps = (dispatch) => ({
    createPlan: (payload) => dispatch(createPlan(payload)),
    updatePlan: (payload) => dispatch(updatePlan(payload)),
    clearStripeError: (payload) => dispatch(clearStripeError(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(TierCard)
