"use strict";

var _ = require('lodash');
var requestAgent;

var PagedParams;

PagedParams = module.exports =  function(url, parms){
  this.q;
  this.uri = url;
  if(!parms) parms = {};
  this.sortFields = [];

  if(parms.payload){
    this.payload = parms.payload;
  }

  this.verb = (parms.verb) ? parms.verb : 'GET';
  this.debug = (parms.debug) ? parms.debug : false;
  this.limitValue = (parms.limit) ? parms.limit : 20;
  this.offsetValue = (parms.offset) ? parms.offset : 0;
  this.useAgent = (parms.useAgent === true) ? parms.useAgent : false;
  this.disableQueryParams = (parms.disableQueryParams) ? parms.disableQueryParams : false;
  this.browserToken = parms.token;

  this.ssoToken = parms.ssoToken;

  this.apiToken = parms.apiToken;

  this.request = parms.agent;

  // if(this.useAgent === true){
  //   if(!requestAgent) requestAgent = superAgent;
  //   this.request = requestAgent.agent();
  // }
  // else{
  //   this.request = superAgent.agent();
  // }

  var getCookie= function(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
  };


  if(!this.ssoToken && typeof document !== 'undefined' && document.cookie){
    var cpmSSOToken = getCookie('cpm-sso-token');
    this.ssoToken = cpmSSOToken;
  }

  return this;
};

PagedParams.prototype.setRequestType = function(type){
  this.requestType = type;
  return this;
};

PagedParams.prototype.followRedirects = function(num){
  this.followRedirects = num;
  return this;
};

PagedParams.prototype.limit = function(limit){
  this.limitValue = limit;
  return this;
};

PagedParams.prototype.simulateCname = function(cname){
  this.simulatedCname = cname;
  return this;
};

PagedParams.prototype.offset = function(offset){
  this.offsetValue = offset;
  return this;
}

PagedParams.prototype.useBrowserToken = function(token){
  this.browserToken = token;
  return this;
}

PagedParams.prototype.setSSOToken = function(token){
  this.ssoToken = token;
  return this;
};

PagedParams.prototype.setFilter = function(filterQuery){

  this.filter = filterQuery;

  return this;
};

PagedParams.prototype.setSortFields = function(fieldsArray){
  this.sortFields = fieldsArray;
  return this;
};

PagedParams.prototype.setOrderByAsc = function(){
  this.orderBy = 'asc';
  return this;
};

PagedParams.prototype.setOrderByDesc = function(){
  this.orderBy = 'desc';
  return this;
};

PagedParams.prototype.setQueryParams = function(params){
  this.queryParams = params;
  return this;
};


// PagedParams.prototype.sortAscendingField = function(fieldName){
//   this.sortFields[fieldName] = 1;
//   return this;
// };
//
// PagedParams.prototype.sortDescendingField = function(fieldName){
//   this.sortFields[fieldName] = 0;
//   return this;
// };

PagedParams.prototype.attachFile = function(name, file){
  this.file = file;
  this.fileName = name;
  return this;
};

PagedParams.prototype.getFormattedParams = function(){

  var a = (this.uri.indexOf('?') >-1) ? '&' : '?';

  if(this.disableQueryParams === true){
    return '';
  }

  if(this.verb === 'GET'){


    var path = a + 'limit=' + this.limitValue + '&offset=' + this.offsetValue;

    var sortStrings = [];

    if(this.sortFields && this.sortFields.length >0){
      path = path + '&sort=' + encodeURIComponent(this.sortFields.join(','));
    }

    if(this.orderBy){
      path = path + '&orderBy=' + encodeURIComponent(this.orderBy);
    }

    // if(sortStrings.length >0){
    //   path = path + '&sort=' + sortStrings.join(',');
    // }

    if(this.filter){
      path = path + '&filter=' + encodeURIComponent(this.filter);
    }

    if(this.simulatedCname){
      path = path + '&cname=' + this.simulatedCname;
    }

    // if(this.browserToken){
    //   path = path + '&__CPM_OR_CLIENT_TOKEN=' + this.browserToken;
    // }


    // if(this.ssoToken){
    //   path = path + '&x-auth-token=' + encodeURIComponent(this.ssoToken);
    // }

    if(this.queryParams){
      var params = this.queryParams;
      _.each(Object.keys(this.queryParams), function(p){
        path = path + '&' + p + '=' + encodeURIComponent(params[p]);
      });
    }

    return path;
  }
  else{

    // var path = '?__CPM_OR_CLIENT_TOKEN=' + this.browserToken;
    var path = '?wc=1';

    // if(this.ssoToken){
    //   path = path + '&x-auth-token=' + encodeURIComponent(this.ssoToken);
    // }


    if(this.queryParams){

      for(var p in this.queryParams){
        path = path + '&' + p + '=' + encodeURIComponent(this.queryParams[p]);
      }

      // _.each(this.queryParams, function(p){
      //   path = path + '&' + p + '=' + encodeURIComponent(this.queryParams[p]);
      // });
    }

    return path;
  }



};

PagedParams.prototype.exec = function(callback){

  var responseHandler = function (error, response) {
    if (!error && response.status == 200) {
      console.log('HERE IS THE RESPONSE', response);
      ////console.log(body) // Show the HTML for the Google homepage.
      if(self.debug===true){
        console.log('________ CLIENT DEBUG _______');
        console.log('Success', fullUri);
        console.log(response.body);
        console.log('________ END CLIENT DEBUG _______');
      }
      if(response.body.results){
        if(callback)
          callback(null, response.body.results, response);
      }
      else{
        if(callback)
          callback(null, response.body, response);
      }

    }
    else{
      var e ={
        error: 'Got error talking to: ' + fullUri,
        details: (response && response.body) ? response.body : error
      };

      if(self.debug === true ){
        console.log('________ CLIENT DEBUG _______');
        console.log('Failed', fullUri);
        console.log('Error', e);
        if(response)
          console.log('Body', response.body);
        console.log('________ END CLIENT DEBUG _______');

      }

      if(callback)
        callback(e, (response)?response.body : null, response);

    }
  };


  var fullUri = this.uri + this.getFormattedParams.call(this);

  var self = this;



  // req.headers['api-token'] = this.apiToken;

  var reqParms = {
    url: fullUri,
    method: this.verb
  };

  if(this.apiToken){
    reqParms.headers = {
      'api-token': this.apiToken
    }
  }

  if(this.ssoToken){
    reqParms.headers = {
      'x-auth-token': this.ssoToken
    }
  }

  var req;

  if(this.verb === 'GET'){
    req = this.request.get(fullUri);
  }
  else{

    if (this.verb === 'POST'){
      req = this.request.post(fullUri);
    }
    if(this.verb === 'PUT'){
      req = this.request.put(fullUri);
    }
    if(this.verb === 'DELETE'){
      req = this.request.del(fullUri);
    }

    if(req && this.requestType)
      req = req.type(this.requestType);
  }

  if(this.followRedirects && !isNaN(this.followRedirects)){
    req = req.redirects(this.followRedirects);
  }

  req.set('Accept', 'application/json');

  if(this.ssoToken){
    req = req.set('x-auth-token', this.ssoToken);
  }

  if(this.apiToken){

    req = req.set('api-token', this.apiToken);
    // req.headers['api-token'] = this.apiToken;
  }

  req = req.withCredentials();

  // Superagent no longer supports using the send() function in the browser when uploading files!
  // was working on 1.2.x.  We are now on 1.8.5ish
  if(this.file){
    req = req.attach(this.fileName, this.file);

    // if(typeof(window) !== 'undefined'){
      req.then(function(result){
        responseHandler(null, result);
      })
        .catch(function(err){
          responseHandler(err);
        })
    // }
    // else{
    //   req.end(responseHandler);
    // }

  }
  else{
    if(this.payload){
      req = req.send(this.payload);
    }

    req.end(responseHandler);
  }

};
