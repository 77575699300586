import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import './ImageTemplateModal.css';

const ImageTemplateModal = ({ visible, toggle, imageLayouts, createImageTemplate }) => {
  const [step, setStep] = React.useState(1);
  const [selectedLayout, setSelectedLayout] = React.useState(null);

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [ctaText, setCtaText] = useState('');

  const [ color, setColor ] = useState('#000000');
  const [ showColorPicker, setShowColorPicker ] = useState(true);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleLayoutSelect = (layout) => {
    setSelectedLayout(layout);
    nextStep();
  };

  const toggleColorPicker = () => setShowColorPicker(!showColorPicker);

  const renderEditableField = ( field ) => {
    switch (field) {
      case 'title':
        return (
          <>
            <label> Title: </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="form-input"
            />
          </>
        );
      case 'siteText':
        return (
          <>
            <label> Site Text: </label>
            <textarea
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              rows={3}
              className="form-textarea"
            />
          </>
        );
      case 'imageUrl':
        return (
          <>
            <label> Image URL: </label>
            <input
              type="text"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              className="form-input"
            />
          </>
        );
      case 'ctaText':
        return (
          <>
            <label> CTA Text: </label>
            <input
              type="text"
              value={ctaText}
              onChange={(e) => setCtaText(e.target.value)}
              className="form-input"
            />
          </>
        );
      case 'color':
        return (
          <div style={{ position: 'relative'}} >
            {/*{showColorPicker ? <div className={'image-template-color-picker_container'}><HexColorPicker color={color} onChange={setColor} /></div> : null}*/}
            <p onClick={toggleColorPicker} className={'input-button'}>{color}</p>
          </div>
        );
      default:
        return null;
    }

  }


  const step1 = (
    <div>
      <h3>Choose from one of the following layouts:</h3>
      <div className="image-templates-container">
        {imageLayouts.layouts.map((layout, i) => (
          <div
            onClick={() => handleLayoutSelect(layout)}
            key={i}
            className="image-template"
          >
            <img src={layout.imageUrl} alt={layout.alt || `Layout-${i}`} className="template-image" />
          </div>
        ))}
      </div>
    </div>
  );

  const step2 = () => (
    <div className="editor-container">
      {/* Form Column */}
      <div className="editor-form">
        <h3>Edit Text</h3>
        <form className="form">
          {selectedLayout.params.map((field, i) => (
            <div key={i} className="form-group">
              {renderEditableField(field)}
            </div>
          ))}
        </form>
      </div>

      {/* Image Display Column */}
      <div className="image-display">
        {selectedLayout && (
          <img
            src={selectedLayout.imageUrl}
            alt={selectedLayout.alt || selectedLayout.name}
            className="selected-template-image"
          />
        )}
      </div>
    </div>
  );

  return (
    <Modal isOpen={visible} toggle={toggle} className="image-template-modal">
      <ModalBody>
        {step === 1 && step1}
        {step === 2 && step2()}
      </ModalBody>
      <ModalFooter>
        {step === 2 && <Button color="primary" onClick={prevStep}>Back</Button>}
        <Button color="secondary" onClick={toggle}>Cancel</Button>
        {step === 2 && <Button color="primary" onClick={() => createImageTemplate({ layoutId: selectedLayout.id, title, image: imageUrl, siteText: subtitle, ctaText })}>Create</Button>}
      </ModalFooter>
    </Modal>
  );
};

export default ImageTemplateModal;
